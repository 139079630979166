import {
  ChartSquareBarIcon,
  CursorClickIcon,
  ScaleIcon,
} from "@heroicons/react/outline";

const supportLinks = [
  {
    name: "Mitigate your risks with a proven process.",
    href: "#",
    description:
      "Large scale soft­ware pro­jects carry risk. This is nat­ural in a highly com­plex en­vi­ron­ment. However, if left unchecked these risks can blow the pro­jects bud­get and se­verely im­pact the qual­ity of the fi­nal prod­uct. Benefit from a tried and tested Way of Work­ing that’s been de­signed to mit­i­gate these risks.",
    icon: ScaleIcon,
  },
  {
    name: "Scale up, in­crease your rev­enue and re­duce your costs.",
    href: "#",
    description:
      "With cus­tom soft­ware you can scale up know­ing you have an ap­pli­ca­tion that can scale with you. It opens new av­enues for li­cens­ing rev­enue since you own the in­tel­lec­tual prop­erty! Grow your busi­ness with this new stream of re­cur­ring rev­enue or, al­ter­na­tively, by sav­ing costs au­tomat­ing those time-con­sum­ing tasks.",
    icon: ChartSquareBarIcon,
  },
  {
    name: "Full pro­to­type and cus­tomised UI.",
    href: "#",
    description:
      "A high-fi­delity pro­to­type will be cre­ated dur­ing scope to vi­su­ally rep­re­sent the prod­uct. During product de­vel­op­ment, the de­signer has greater free­dom to de­velop a cus­tomised UI which will match your brand guide­lines and your pref­er­ences for the look and feel of the re­sult­ing ap­pli­ca­tion. A be­spoke de­sign for your app is es­sen­tial to meet­ing the needs of your users.",
    icon: CursorClickIcon,
  },
];

export default function Header() {
  return (
    <>
      <div className="bg-gray-50">
        {/* Header */}
        <div className="relative pb-32 bg-gray-800">
          <div className="absolute inset-0">
            <img
              className="w-full h-full object-cover"
              src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100"
              alt=""
            />
            <div
              className="absolute inset-0 bg-gray-800 mix-blend-multiply"
              aria-hidden="true"
            />
          </div>
          <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">
              Product Development
            </h1>

            <p className="mt-6 max-w-3xl text-xl text-gray-300">
              Product de­vel­op­ment cov­ers the com­plete process of bring­ing
              a new prod­uct to mar­ket, re­new­ing an ex­ist­ing prod­uct or
              in­tro­duc­ing a prod­uct in a new mar­ket.
            </p>
            <p className="mt-6 max-w-3xl text-xl text-gray-300">
              Scope, build and launch your prod­uct us­ing a qual­ity dri­ven
              process.
            </p>
          </div>
        </div>

        {/* Overlapping cards */}
        <section
          className="-mt-32 max-w-7xl mx-auto relative z-10 pb-16 px-4 sm:px-6 lg:px-8"
          aria-labelledby="contact-heading"
        >
          <h2 className="sr-only" id="contact-heading">
            Contact us
          </h2>
          <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
            {supportLinks.map((link) => (
              <div
                key={link.name}
                className="flex flex-col bg-white rounded-2xl shadow-xl"
              >
                <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                  <div className="absolute top-0 p-5 inline-block bg-gray-700 rounded-xl shadow-lg transform -translate-y-1/2">
                    <link.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <h3 className="text-xl font-medium text-gray-900">
                    {link.name}
                  </h3>
                  <p className="mt-4 text-base text-gray-500">
                    {link.description}
                  </p>
                </div>
                {/* <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                    <a
                      href={link.href}
                      className="text-base font-medium text-indigo-700 hover:text-indigo-600"
                    >
                      Contact us<span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div> */}
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}
