import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

const faqs = [
  {
    id: 1,
    question:
      "Why would I choose Product Development or MVP when POC is cheap­est?",
    answer:
      "Sometimes the cheap­est op­tion is­n’t right for you. When build­ing a proof of con­cept, you’re val­i­dat­ing an idea. That may mean de-pri­ori­tis­ing us­abil­ity or the in­ter­face of the ap­pli­ca­tion.",
  },
  {
    id: 2,
    question: "Can I build more to im­prove my POC later?",
    answer:
      "Yes, you can make small changes to your proof of con­cept on a time and ma­te­ri­als ba­sis through your ser­vice desk while in sup­port. However, if you’re look­ing to make sig­nif­i­cant changes it may be nec­es­sary to go through a Product Development or MVP en­gage­ment. Based on your learn­ings and the changes to be made the team will rec­om­mend whether it’s bet­ter to add to the POC ap­pli­ca­tion or com­mence a re-build.",
  },
  {
    id: 3,
    question: "What do I do if POC is out­side my bud­get?",
    answer:
      "We’ve found that in or­der to build qual­ity cus­tom soft­ware you need a bud­get greater than $20K. There are op­tions avail­able if you don’t have the bud­get. The first op­tion we rec­om­mend is to find an off the shelf sys­tem that solves the prob­lem. Failing that, you may want to try to find a tech­ni­cal co-founder - some­one that can build the ap­pli­ca­tion in ex­change for eq­uity. If you pro­ceed down this path we rec­om­mend us­ing no-code solutions to lever­age pre-built func­tion­al­ity and speed up de­vel­op­ment. The last op­tion is to off­shore to an over­seas de­vel­op­ment house. We only rec­om­mend this as a last re­sort as there are risks as­so­ci­ated with many off­shore agen­cies.",
  },
  {
    id: 4,
    question: "What is­n’t a proof of con­cept?",
    answer:
      "A proof of con­cept is not a com­mer­cially vi­able ap­pli­ca­tion. Don’t ex­pect to pro­ceed through a POC en­gage­ment and get 20,000 users on your ap­pli­ca­tion. If you’re plan­ning on cre­at­ing a com­mer­cially vi­able prod­uct, then the MVP or Product Development en­gage­ments are bet­ter suited to your re­quire­ments.",
  },
  {
    id: 5,
    question: "Will I get my en­tire back­log in my POC?",
    answer:
      "No, do not ex­pect to have your en­tire back­log built dur­ing a POC. The en­gage­ment is only two weeks and three days. There is no guar­an­tee as to the scope that will be de­liv­ered in that time. That’s why it’s crit­i­cal to pri­ori­tise your back­log and fo­cus on a sin­gle prob­lem state­ment.",
  },
  {
    id: 6,
    question: "Will I get a chance to pro­vide feed­back on my POC?",
    answer:
      "Yes, there will be an op­por­tu­nity to pro­vide feed­back and re-pri­ori­tise your back­log dur­ing the POC en­gage­ment. We sched­ule user ac­cep­tance test­ing (UAT’s) 2.5 days be­fore the end of the en­gage­ment. This will give you an op­por­tu­nity to re­view the ap­pli­ca­tion and pri­ori­tise the work to be done in the fi­nal 2.5 days of the en­gage­ment.",
  },
  {
    id: 7,
    question: "How will I be billed?",
    answer:
      "You will be billed in weekly in­cre­ments on the Wednesday pre­ced­ing the start of the en­gage­ment, with 7 day pay­ment terms.",
  },
  {
    id: 8,
    question: "What if I’m not happy at the end of the three day work­shop?",
    answer:
      "There is a nat­ural break point at the end of the work­shop if both par­ties aren’t aligned on re­al­is­tic ob­jec­tives. In the event of a mis­align­ment, the en­gage­ment can be ter­mi­nated af­ter the work­shop and only the days worked are bill­able.",
  },
  // More questions...
];

export default function Example() {
  return (
    <div className="bg-gray-900">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-900">
          <h2 className="text-center text-3xl font-extrabold text-white sm:text-4xl">
            Frequently asked questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-400">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button
                        className="text-left w-full flex justify-between items-start text-gray-300"
                        style={{ outline: 0 }}
                      >
                        <span className="font-medium text-gray-100">
                          {faq.question}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={
                              open
                                ? "-rotate-180 h-6 w-6 transform"
                                : "rotate-0 h-6 w-6 transform"
                            }
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-400">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

// export default function Example() {
//   return (
//     <div className="bg-gray-900">
//       <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
//         <div className="lg:max-w-2xl lg:mx-auto lg:text-center">
//           <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
//             Frequently asked questions
//           </h2>
//           <p className="mt-4 text-gray-400">
//             Ac euismod vel sit maecenas id pellentesque eu sed consectetur.
//             Malesuada adipiscing sagittis vel nulla nec. Urna, sed a lectus
//             elementum blandit et.
//           </p>
//         </div>
//         <div className="mt-20">
//           <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
//             {faqs.map((faq) => (
//               <div key={faq.id}>
//                 <dt className="font-semibold text-white">{faq.question}</dt>
//                 <dd className="mt-3 text-gray-400">{faq.answer}</dd>
//               </div>
//             ))}
//           </dl>
//         </div>
//       </div>
//     </div>
//   );
// }
