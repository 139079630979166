import {
  // NewspaperIcon,
  // PhoneIcon,
  // SupportIcon,
  CheckIcon,
} from "@heroicons/react/outline";
import Included from "./Included";
import ContactUs from "../../components/ContactUs";
import Header from "./Header";
import FAQ from "./FAQ";
import { useEffect } from "react";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";
import HeaderNav from "../../components/HeaderNav";

// const supportLinks = [
//   {
//     name: "Low risk and light­ning fast.",
//     href: "#",
//     description:
//       "POCs are time-fixed, com­pris­ing of a 3-day scop­ing work­shop and 2 weeks of de­vel­op­ment. This sets your bud­get from the get go and al­lows you to val­i­date your idea with users quickly. A POC is a low-risk way of let­ting in­vestors know you can ex­e­cute on your vi­sion.",
//     icon: PhoneIcon,
//   },
//   {
//     name: "In the dri­ver’s seat.",
//     href: "#",
//     description:
//       "At the con­clu­sion of POC de­vel­op­ment you have the op­tion to make small changes to the ap­pli­ca­tion via our ser­vice desk. Bigger changes may ne­ces­si­tate an MVP or Product Development engagement, but you have full con­trol over how much you want to add to the app and when.",
//     icon: SupportIcon,
//   },
//   {
//     name: "Project Artefacts.",
//     href: "#",
//     description:
//       "Aside from your POC ap­pli­ca­tion, our de­sign­ers and de­vel­op­ers will also cre­ate user flow di­a­grams, a back­log and UI de­signs, along with any other arte­facts in­ci­den­tal to the work­shop. These arte­facts can in­form any fu­ture de­vel­op­ment you may choose to do.",
//     icon: NewspaperIcon,
//   },
// ];

const features = [
  {
    name: "Idea needs validation",
    description:
      "You need to val­i­date your so­lu­tion to raise fur­ther in­vest­ment.",
  },
  {
    name: "Simple solution",
    description:
      "Your so­lu­tion is a very sim­ple web plat­form with lim­ited fea­tures and user groups.",
  },
  {
    name: "Requires technical investigation",
    description:
      "There are tech­ni­cal un­knowns that must be in­ves­ti­gated be­fore build­ing the com­plete prod­uct.",
  },
];

function IsItRight() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div
          className="max-w-3xl mx-auto text-center"
          style={{ marginBottom: 24 }}
        >
          <h2 className="text-base font-semibold text-indigo-600 uppercase tracking-wide">
            Proof of Concept
          </h2>
          <h2 className="text-3xl font-extrabold text-gray-900">
            When is POC best for you?
          </h2>
          <p className="mt-4 text-lg text-gray-500 mb-24">
            If these points don’t de­scribe you or your busi­ness, we’d
            rec­om­mend tak­ing a look at our MVP or Product Development
            en­gage­ments.
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon
                  className="absolute h-6 w-6 text-green-500"
                  aria-hidden="true"
                />
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}

export default function Example() {
  const { pathname } = useLocation();
  useEffect(() => {
    /* settimeout make sure this run after components have rendered. This will help fixing bug for some views where scroll to top not working perfectly */
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, [pathname]);

  return (
    <>
      <HeaderNav />
      <Header />
      <Included />
      <IsItRight />
      <FAQ />
      <ContactUs />
      <Footer />
    </>
  );
}
