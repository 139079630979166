import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

const faqs = [
  {
    question: "How do I pri­ori­tise my back­log?",
    answer:
      "In sim­ple terms, from most im­por­tant to least im­por­tant. It’s vi­tal to con­tin­u­ously re­view it as it forms the or­der that the fea­tures will be built. So, when the four weeks of de­vel­op­ment con­cludes, it is the fea­tures at the bot­tom of the back­log that don’t form part of the MVP.",
  },
  {
    question: "Can I build more to im­prove my MVP later?",
    answer:
      "Yes. This is the pur­pose of the MVP en­gage­ment. By build­ing a min­i­mum vi­able prod­uct and test­ing it, your mar­ket will tell you what to build next.",
  },
  {
    question:
      "Can I ex­tend de­vel­op­ment if a fea­ture must be part of the MVP?",
    answer:
      "Yes. While the aim of the MVP en­gage­ment is to be flex­i­ble with your scope, if it is crit­i­cal that a fea­ture form part of the MVP then you can ex­tend de­vel­op­ment to in­clude it in the pro­duc­tion re­lease.",
  },
  {
    question: "When will I get to see my MVP?",
    answer:
      "There will be mul­ti­ple op­por­tu­ni­ties to re­view your MVP. At the end of each it­er­a­tion (two weeks of de­vel­op­ment) there will be user ac­cep­tance test­ing. This is your op­por­tu­nity to re­view the fea­tures built and re-pri­ori­tise the back­log.",
  },
  {
    question: "What isn't an MVP?",
    answer:
      "An MVP is not your en­tire roadmap or back­log. The min­i­mum vi­able prod­uct is the lean­est ver­sion of your prod­uct that will solve your prob­lem state­ment. If you need your en­tire back­log built then the Product Development en­gage­ment may be a bet­ter fit.",
  },
  {
    question: "How will I be billed?",
    answer:
      "You will be billed in weekly in­cre­ments on the Wednesday pre­ced­ing the start of the en­gage­ment, with 7 day pay­ment terms.",
  },
  {
    question: "Will I get everything I want in my MVP?",
    answer:
      "Maybe. It’s a dif­fi­cult ques­tion to an­swer now since we don’t yet know what you want. Chances are, if you have a big list of re­quire­ments, you won’t get every­thing in an MVP. We will know more at the end of the five day work­shop and we’ll guide you on what is rea­son­able to ex­pect. If you’re not happy at that point in time, you can end the en­gage­ment (see the FAQ be­low for more de­tails).",
  },
  {
    question: "What if I'm not happy at the end of the five day workshop?",
    answer:
      "There is a nat­ural break point at the end of the work­shop if both par­ties aren’t aligned on re­al­is­tic ob­jec­tives. In the event of a mis­align­ment, the en­gage­ment can be ter­mi­nated af­ter the work­shop and only the days worked are bill­able.",
  },
  // More questions...
];

export default function Example() {
  return (
    <div className="bg-gray-900">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-900">
          <h2 className="text-center text-3xl font-extrabold text-white sm:text-4xl">
            Frequently asked questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-400">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button
                        className="text-left w-full flex justify-between items-start text-gray-300"
                        style={{ outline: 0 }}
                      >
                        <span className="font-medium text-gray-100">
                          {faq.question}
                        </span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={
                              open
                                ? "-rotate-180 h-6 w-6 transform"
                                : "rotate-0 h-6 w-6 transform"
                            }
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-400">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

// export default function Example() {
//   return (
//     <div className="bg-gray-900">
//       <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
//         <div className="lg:max-w-2xl lg:mx-auto lg:text-center">
//           <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
//             Frequently asked questions
//           </h2>
//           <p className="mt-4 text-gray-400">
//             Ac euismod vel sit maecenas id pellentesque eu sed consectetur.
//             Malesuada adipiscing sagittis vel nulla nec. Urna, sed a lectus
//             elementum blandit et.
//           </p>
//         </div>
//         <div className="mt-20">
//           <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
//             {faqs.map((faq) => (
//               <div key={faq.id}>
//                 <dt className="font-semibold text-white">{faq.question}</dt>
//                 <dd className="mt-3 text-gray-400">{faq.answer}</dd>
//               </div>
//             ))}
//           </dl>
//         </div>
//       </div>
//     </div>
//   );
// }
