/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";

const tiers = [
  {
    name: "3 day work­shop + 2 weeks de­v",
    href: "/services/poc",
    priceMonthly: (
      <>
        Proof of <br />
        Concept
      </>
    ),

    features: [
      "Web plat­form only",
      "AWS set-up",
      "One in­te­gra­tion",
      "One user group",
      "Hi-ﬁ de­signs",
      "Lite back­log",
      "Automated test plan",
      "3-months Product con­sult­ing",
    ],
  },
  {
    name: "5 day work­shop + 4 weeks de­v",
    href: "/services/minimum-viable-product",
    priceMonthly: "Minimum Viable Product (MVP)",

    features: [
      "Web or mo­bile app",
      "AWS set-up",
      "Up to two in­te­gra­tions",
      "Two user groups",
      "Hi-ﬁ pro­to­type",
      "Unmoderated user test­ing",
      "Backlog",
      "Manual test plan",
      "3-months Product con­sult­ing",
    ],
  },
  {
    name: "4 week scope + vari­able de­v",
    href: "/services/poc",
    priceMonthly: "Product Development",

    features: [
      "Web and/​or mo­bile app",
      "Any host­ing en­vi­ron­ment set-up",
      "Unlimited in­te­gra­tions",
      "Discovery in­ter­views",
      "User jour­ney maps",
      "Hi-ﬁ pro­to­type",
      "Unmoderated/Moderated user test­ing",
      "Custom UI",
      "Backlog",
      "Manual test plan",
      "Estimations",
      "Roadmap",
      "3-months Product con­sult­ing",
    ],
  },
];

export default function Example() {
  return (
    <div className="bg-gray-900">
      <div className="pt-12 sm:pt-16 lg:pt-24">
        <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
              Services
            </h2>
            <p className="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
              The right package for you, whoever you are
            </p>
            <p className="text-xl text-gray-300">
              Select the of­fer­ing that’s right for your busi­ness
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div className="relative">
          <div className="absolute inset-0 h-3/4 bg-gray-900" />
          <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto space-y-4 lg:max-w-7xl lg:grid lg:grid-cols-3 lg:gap-5 lg:space-y-0">
              {tiers.map((tier) => (
                <div
                  key={tier.name}
                  className="flex flex-col rounded-lg shadow-lg overflow-hidden"
                >
                  <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <div>
                      <h3
                        className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600"
                        id="tier-standard"
                      >
                        {tier.name}
                      </h3>
                    </div>
                    <div className="mt-4 flex items-baseline text-4xl font-extrabold">
                      {tier.priceMonthly}
                      {/* <span className="ml-1 text-2xl font-medium text-gray-500">
                        /mo
                      </span> */}
                    </div>
                    {/* <p className="mt-5 text-lg text-gray-500">
                      {tier.description}
                    </p> */}
                  </div>
                  <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                    <ul className="space-y-4">
                      {tier.features.map((feature) => (
                        <li key={feature} className="flex items-start">
                          <div className="flex-shrink-0">
                            <CheckIcon
                              className="h-6 w-6 text-green-500"
                              aria-hidden="true"
                            />
                          </div>
                          <p className="ml-3 text-base text-gray-700">
                            {feature}
                          </p>
                        </li>
                      ))}
                    </ul>
                    <div className="rounded-md shadow">
                      <Link
                        to={tier.href}
                        className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                        aria-describedby="tier-standard"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
          <div className="max-w-md mx-auto lg:max-w-7xl">
            <div className="rounded-lg bg-gray-200 px-6 py-8 sm:p-10 lg:flex lg:items-center">
              <div className="flex-1">
                <div>
                  <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-white text-gray-800">
                    Custom
                  </h3>
                </div>
                <div className="mt-4 text-lg text-gray-600">
                  Our do­main ex­perts un­der­stand that every prob­lem is
                  <span className="font-semibold text-gray-900">
                    {" "}
                    unique
                  </span>{" "}
                  and will work closely with you to con­struct a so­lu­tion that
                  de­liv­ers the great­est pos­si­ble ben­e­ﬁt to your
                  busi­ness.
                </div>
              </div>
              <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                <a
                  href={`${window.location.origin}#contact`}
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                >
                  Get Custom Consulting
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
