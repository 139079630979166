// TOP LEVEL IMPORTS
import { Switch, BrowserRouter, Route } from "react-router-dom";
import Home from "./home";
import Careers from "./careers";
// import Terms from './terms';
import Privacy from "./privacy";
import Healthcare from "./healthcare";
import Services from "./services";
import NotFound from "./not-found";
import POC from "./proof-of-concept";
import WebAndBranding from "./web-and-branding";
import MinimumViableProduct from "./minimum-viable-product";
import ProductDevelopment from "./product-development";
import Support from "./support";

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={["/"]} component={Home} />
        <Route exact path={["/careers"]} component={Careers} />
        <Route exact path={["/privacy"]} component={Privacy} />
        <Route exact path={["/healthcare"]} component={Healthcare} />
        <Route exact path={["/services"]} component={Services} />
        <Route exact path={["/services/poc"]} component={POC} />
        <Route
          exact
          path={["/services/minimum-viable-product"]}
          component={MinimumViableProduct}
        />
        <Route
          exact
          path={["/services/web-and-branding"]}
          component={WebAndBranding}
        />
        <Route
          exact
          path={["/services/product-development"]}
          component={ProductDevelopment}
        />
        <Route exact path={["/services/support"]} component={Support} />
        <Route exact component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}
