/* This example requires Tailwind CSS v2.0+ */
import {
  ArrowCircleRightIcon,
  BeakerIcon,
  SparklesIcon,
  CursorClickIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import brandingPNG from "./website.png";

export default function Example() {
  return (
    <>
      <div className="relative bg-white pt-16 pb-32 overflow-hidden">
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-200">
                    <BeakerIcon
                      className="h-6 w-6 text-gray"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Proof of Concept
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Proof of Concept builds are per­fect for pro­jects which
                    need in­vest­ment be­fore full-scale de­vel­op­ment, or for
                    shorter ex­per­i­ments.
                  </p>
                  <p className="mt-4 text-lg text-gray-500">
                    A POC takes place over a pe­riod of 3 weeks and is of­fered
                    at a ﬁxed price. The jour­ney be­gins with a 3-day
                    work­shop, fol­lowed by 2 weeks of de­vel­op­ment.
                  </p>
                  <p className="mt-4 text-lg text-gray-500">
                    Due to the short du­ra­tion of scope and de­vel­op­ment, we
                    hone in on the es­sen­tials of the idea and keep the
                    pro­ject fo­cus as tight as pos­si­ble. The goal with a POC
                    is to ei­ther sup­port the busi­ness case for fur­ther
                    de­vel­op­ment or equip you with the col­lat­eral needed to
                    at­tract in­vestors.
                  </p>

                  <div className="mt-6">
                    <Link
                      to="/services/poc"
                      target="_blank"
                      rel="noreferrer"
                      className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-800 hover:bg-gray900"
                    >
                      More About Proof of Concept{" "}
                      <ArrowCircleRightIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                        style={{ marginLeft: 8, position: "relative", top: 3 }}
                      />
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    &ldquo;Cras velit quis eros eget rhoncus lacus ultrices sed
                    diam. Sit orci risus aenean curabitur donec aliquet. Mi
                    venenatis in euismod ut.&rdquo;
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-6 w-6 rounded-full"
                        src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                        alt=""
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">
                      Marcia Hill, Digital Marketing Manager
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div> */}
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src="https://tailwindui.com/img/component-images/inbox-app-screenshot-1.jpg"
                  alt="Inbox user interface"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-24">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-200">
                    <CursorClickIcon
                      className="h-6 w-6 text-gray"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    MVP
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    MVP, or Minimum Viable Product, are for lean prod­ucts with
                    very clear use cases. A 5-day work­shop will re­sult in user
                    ﬂow di­a­grams, a hi-ﬁ pro­to­type and a back­log ready for
                    de­vel­op­ment. This is fol­lowed by 4 weeks of
                    de­vel­op­ment.
                  </p>
                  <p className="mt-4 text-lg text-gray-500">
                    MVPs are a great starter op­tion for busi­nesses who need
                    be­spoke soft­ware but want to start small. The re­sult­ing
                    ap­pli­ca­tion typ­i­cally re­solves a spe­ciﬁc prob­lem in
                    the busi­ness and pro­vides a foun­da­tion for fur­ther
                    de­vel­op­ment down the track.
                  </p>

                  <div className="mt-6">
                    <Link
                      to="services/minimum-viable-product"
                      target="_blank"
                      rel="noreferrer"
                      className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-800 hover:bg-gray900"
                    >
                      More About MVP{" "}
                      <ArrowCircleRightIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                        style={{ marginLeft: 8, position: "relative", top: 3 }}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src="https://tailwindui.com/img/component-images/inbox-app-screenshot-2.jpg"
                  alt="Customer profile user interface"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
      
      
      
      
      */}
      <div className="relative bg-white pt-16 pb-32 overflow-hidden">
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center bg-gray-200">
                    <RefreshIcon
                      className="h-6 w-6 text-gray"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Product Development
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Our Product Development is for busi­nesses that have the
                    bud­get and time in­vest­ment nec­es­sary to tackle more
                    com­plex and am­bi­tious soft­ware needs.
                  </p>
                  <p className="mt-4 text-lg text-gray-500">
                    During Product Development, a ded­i­cated Product Designer
                    and Developer team will iden­tify the op­ti­mal user ﬂow for
                    your prod­uct, re­search your users and iden­tify
                    tech­ni­cal risks and op­por­tu­ni­ties. The re­sult­ing
                    back­log and hi-ﬁ pro­to­type is then used as a source of
                    truth for es­ti­ma­tions. These es­ti­ma­tions will
                    in­di­cate the length and cost for your pro­ject.
                  </p>
                  <p className="mt-4 text-lg text-gray-500">
                    If your busi­ness needs help un­tan­gling a dif­ﬁ­cult
                    prob­lem and has the in­vest­ment to pro­ceed, this is the
                    ser­vice of­fer­ing we rec­om­mend.
                  </p>

                  <div className="mt-6">
                    <a
                      href={`${window.location.origin}/services/product-development`}
                      target="_blank"
                      rel="noreferrer"
                      className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-800 hover:bg-gray900"
                    >
                      More About Product Development
                      <ArrowCircleRightIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                        style={{ marginLeft: 8, position: "relative", top: 3 }}
                      />
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p className="text-base text-gray-500">
                    &ldquo;Cras velit quis eros eget rhoncus lacus ultrices sed
                    diam. Sit orci risus aenean curabitur donec aliquet. Mi
                    venenatis in euismod ut.&rdquo;
                  </p>
                </div>
                <footer className="mt-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-6 w-6 rounded-full"
                        src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                        alt=""
                      />
                    </div>
                    <div className="text-base font-medium text-gray-700">
                      Marcia Hill, Digital Marketing Manager
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div> */}
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                  src="https://tailwindui.com/img/component-images/top-nav-with-multi-column-layout-screenshot.jpg"
                  alt="Inbox user interface"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-24">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div>
                <div>
                  <span className="h-12 w-12 rounded-md flex items-center justify-center  bg-gray-200">
                    <SparklesIcon
                      className="h-6 w-6 text-gray"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-6">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                    Websites and Branding
                  </h2>
                  <p className="mt-4 text-lg text-gray-500">
                    Having an on­line pres­ence is es­sen­tial for most
                    busi­nesses. A re­spon­sive, brand-aligned mar­ket­ing
                    web­site boosts your cred­i­bil­ity in the in­dus­try,
                    in­forms your tar­get au­di­ence and gives you the tools you
                    need to gain use­ful con­sumer in­sights. A mar­ket­ing
                    web­site gives prod­uct own­ers a way to reach out to an
                    au­di­ence in a way that pushes the brand to its cre­ative
                    limit.
                  </p>
                  <p className="mt-4 text-lg text-gray-500">
                    During Websites and Branding, a ded­i­cated Product Designer
                    and Developer team will iden­tify the op­ti­mal de­signs for
                    your prod­uct, re­search your users and iden­tify where your
                    busi­ness ﬁts in the mar­ket. The re­sult is a brand for
                    your busi­ness and a mar­ket­ing web­site.
                  </p>
                  <p className="mt-4 text-lg text-gray-500">
                    If your busi­ness needs help get­ting started on set­ting up
                    its brand and reach­ing out to cus­tomers, this is the best
                    op­tion for you.
                  </p>
                  <div className="mt-6">
                    <a
                      href={`${window.location.origin}/services/web-and-branding`}
                      target="_blank"
                      rel="noreferrer"
                      className="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-800 hover:bg-gray900"
                    >
                      More About Websites & Branding{" "}
                      <ArrowCircleRightIcon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                        style={{ marginLeft: 8, position: "relative", top: 3 }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
              <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                  src={brandingPNG}
                  alt="Customer profile user interface"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
