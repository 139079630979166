import Hero from "./Hero";
import Features from "./Services";
import OurWork from "./OurWork";
import Technologies from "./Technologies";
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Footer";
import HealthCTA from "./HealthCTA";

export default function Home() {
  return (
    <div>
      <Hero />
      <Features />
      <OurWork />
      <Technologies />
      <ContactUs />
      <HealthCTA />
      <Footer />
    </div>
  );
}
