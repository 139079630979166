import ContactUs from "../../components/ContactUs";
import Header from "./Header";
import { useEffect } from "react";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";
import IsItRight from "./IsItRight";
import Included from "./Included";
import FAQ from "./FAQ";
import HeaderNav from "../../components/HeaderNav";

export default function MinimumViableProduct() {
  const { pathname } = useLocation();
  useEffect(() => {
    /* settimeout make sure this run after components have rendered. This will help fixing bug for some views where scroll to top not working perfectly */
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, [pathname]);

  return (
    <>
      <HeaderNav />
      <Header />
      <IsItRight />
      <Included />
      <FAQ />
      <ContactUs />
      <Footer />
    </>
  );
}
