import {
  DesktopComputerIcon,
  DocumentSearchIcon,
  TrendingUpIcon,
} from "@heroicons/react/outline";

const supportLinks = [
  {
    name: "Performance",
    href: "#",
    description:
      "In gen­eral, the two-to-three sec­ond mark is how long vis­i­tors to your site will want to wait. Longer than this, and vis­i­tors tend to aban­don a site. This means that en­sur­ing your web­site loads light­ning fast is cru­cial.",
    icon: TrendingUpIcon,
  },
  {
    name: "Responsiveness",
    href: "#",
    description:
      "More peo­ple than ever are surf­ing the web from a mo­bile de­vice — up to 61% ac­cord­ing to some sta­tis­tics. o ac­com­mo­date for this, we de­sign our web­sites both for desk­top and mo­bile de­vices, us­ing break­points in the stylesheet to switch be­tween dif­fer­ent lay­outs when the browser de­tects par­tic­u­lar view­ports.",
    icon: DesktopComputerIcon,
  },
  {
    name: "SEO",
    href: "#",
    description:
      "Search en­gine op­ti­mi­sa­tion is key when it comes to dri­ving traf­fic to your site. Broadly, traf­fic can be di­vided into two modes: or­ganic and in­or­ganic. Organic SEO sim­ply refers to the num­ber of users who type a given search term in a browser and click on your web­site from the re­sults page. Inorganic traf­fic de­rives from af­fil­i­ate mar­ket­ing, paid ads and so­cial me­dia sites.",
    icon: DocumentSearchIcon,
  },
];

export default function Header() {
  return (
    <>
      <div className="bg-gray-50">
        {/* Header */}
        <div className="relative pb-32 bg-gray-800">
          <div className="absolute inset-0">
            <img
              className="w-full h-full object-cover"
              src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100"
              alt=""
            />
            <div
              className="absolute inset-0 bg-gray-800 mix-blend-multiply"
              aria-hidden="true"
            />
          </div>
          <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">
              Websites and Branding
            </h1>

            <p className="mt-6 max-w-3xl text-xl text-gray-300">
              Having an on­line pres­ence is es­sen­tial for most busi­nesses. A
              re­spon­sive, brand-aligned mar­ket­ing web­site boosts your
              cred­i­bil­ity in the in­dus­try, in­forms your tar­get au­di­ence
              and gives you the tools you need to gain use­ful con­sumer
              in­sights. A mar­ket­ing web­site gives prod­uct own­ers a way to
              reach out to an au­di­ence in a way that pushes the brand to its
              cre­ative limit.
            </p>
          </div>
        </div>

        {/* Overlapping cards */}
        <section
          className="-mt-32 max-w-7xl mx-auto relative z-10 pb-16 px-4 sm:px-6 lg:px-8"
          aria-labelledby="contact-heading"
        >
          <h2 className="sr-only" id="contact-heading">
            Contact us
          </h2>
          <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
            {supportLinks.map((link) => (
              <div
                key={link.name}
                className="flex flex-col bg-white rounded-2xl shadow-xl"
              >
                <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                  <div className="absolute top-0 p-5 inline-block bg-gray-700 rounded-xl shadow-lg transform -translate-y-1/2">
                    <link.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <h3 className="text-xl font-medium text-gray-900">
                    {link.name}
                  </h3>
                  <p className="mt-4 text-base text-gray-500">
                    {link.description}
                  </p>
                </div>
                {/* <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                    <a
                      href={link.href}
                      className="text-base font-medium text-indigo-700 hover:text-indigo-600"
                    >
                      Contact us<span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div> */}
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}
