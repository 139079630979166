import Hero from "./Hero";
import OurWork from "./OurWork";
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Footer";
import Technologies from "../home/Technologies";
import Services from "./Services";

export default function Healthcare() {
  return (
    <>
      <div>
        <Hero />
        <Services />
        <OurWork />
        <Technologies />
        <ContactUs />
        <Footer />
      </div>
    </>
  );
}
