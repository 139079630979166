import Footer from "../../components/Footer";
import Hero from "./Hero";
import ProjectTypes from "./ProjectTypes";
import HeaderNav from "../../components/HeaderNav";
import Packages from "./Packages";

export default function Services() {
  return (
    <>
      <div>
        <HeaderNav />
        <Hero />
        <ProjectTypes />
        <Packages />
        <Footer />
      </div>
    </>
  );
}
