/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/

import moment from "moment";
import config from "../../lib/config";
import HeaderNav from "../../components/HeaderNav";

const DATE_FORMAT = `MMM DD, YYYY`;
const DATETIME_FORMAT = `YYYY-MM-DD`;

const posts = [
  {
    title: "Frontend Developer",
    href: `mailto:${config.supportEmail}?subject=Frontend Engineer`,
    description: `Paper Clouds is seeking a software engineer to join the front end team. You will be responsible for building innovative interfaces used by a diverse set of customers. Ideal candidate will have experience with React.js, GraphQL (apollo client) and styled-components.`,
    date: moment().subtract(10, "days").format(DATE_FORMAT),
    datetime: moment().subtract(10, "days").format(DATETIME_FORMAT),
  },
  {
    title: "Fullstack Developer",
    href: `mailto:${config.supportEmail}?subject=Fullstack Engineer`,
    description: `Paper Clouds is seeking a software engineer to join the Paper Clouds team. Ideal candidate will have experience with at least some of the following: React.js, GraphQL (apollo client/server), Heroku, Mongoose, Mailgun Netlify, Dataloader, Node.js, MongoDB, GCS and Braintree.`,
    date: moment().subtract(5, "days").format(DATE_FORMAT),
    datetime: moment().subtract(5, "days").format(DATETIME_FORMAT),
  },
  {
    title: "UX Designer",
    href: `mailto:${config.supportEmail}?subject=UX Designer`,
    description:
      "PaperClouds is hiring a senior-level UX designer with strong visual design experience to join our product design team supporting top-tier clients. You have a passion and track record for designing exceptional product experiences. Your love for design includes the need for deep understanding of customers and their needs and you have the ability to understand and solve complex problems. You will play a key role on our design team and help direct and shape projects for some of the best companies in the world.",
    date: moment().subtract(14, "days").format(DATE_FORMAT),
    datetime: moment().subtract(14, "days").format(DATETIME_FORMAT),
  },
  {
    title: "UI Designer",
    href: `mailto:${config.supportEmail}?subject=UI Designer`,
    description:
      "PaperClouds is hiring a UI designer with strong visual design experience to join our product design team supporting top-tier clients. Your love for design includes the need for deep understanding of customers and their needs and you have the ability to understand and solve complex problems.",
    date: moment().subtract(21, "days").format(DATE_FORMAT),
    datetime: moment().subtract(21, "days").format(DATETIME_FORMAT),
  },
];

export default function Example() {
  return (
    <>
      <HeaderNav />
      <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
          <div>
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              Careers @ {config.companyName}
            </h2>
            <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
              <p className="text-xl text-gray-500">Help us build cool stuff.</p>
            </div>
          </div>
          <div className="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
            {posts.map((post) => (
              <div key={post.title}>
                <p className="text-sm text-gray-500">
                  <time dateTime={post.datetime}>{post.date}</time>
                </p>

                <p className="text-xl font-semibold text-gray-900">
                  {post.title}
                </p>
                <p className="mt-3 text-base text-gray-500">
                  {post.description}
                </p>

                <div className="mt-3">
                  <a
                    href={post.href}
                    className="text-base font-semibold text-gray-600 hover:text-gray-500"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
