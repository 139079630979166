/* This example requires Tailwind CSS v2.0+ */
import {
  AdjustmentsIcon,
  CollectionIcon,
  DesktopComputerIcon,
  DeviceMobileIcon,
  RefreshIcon,
  ServerIcon,
  SupportIcon,
  UserAddIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";

const features = [
  {
    name: "5 Day Workshop + 20 Development Days",
    description:
      "Start rapidly pro­to­typ­ing your MVP over the course of a 5 day work­shop. This leads straight into a lean 4 week build to get you a prod­uct ready to start test­ing!",
    icon: UserGroupIcon,
  },
  {
    name: "Up to 2 API in­te­gra­tions",
    description:
      "An API (application pro­gram­ming in­ter­face) in­te­gra­tion is how dif­fer­ent sys­tems com­mu­ni­cate with each other. Connect your ap­pli­ca­tions to re­duce data en­try, au­to­mate tasks, re­duce hu­man er­ror and save costs.",
    icon: AdjustmentsIcon,
  },
  {
    name: "Product Backlog",
    description:
      "A prod­uct back­log tells your de­vel­op­ment team ex­actly what to build. This creates the di­rec­tion and struc­ture you need to de­liver a suc­cess­ful pro­ject.",
    icon: CollectionIcon,
  },
  {
    name: "Up to 2 User Groups",
    description:
      "A user group is a type of user likely to in­ter­act with your ap­pli­ca­tion. With mul­ti­ple user groups you can con­nect one au­di­ence, with an­other.",
    icon: UserAddIcon,
  },
  {
    name: "2nd Level Priority for Service Desk Support",
    description:
      "You won’t be left stranded af­ter the build is done. Support is pro­vided to save the day, with MVP prod­ucts get­ting sec­ond level ser­vice desk pri­or­ity.",
    icon: SupportIcon,
  },
  {
    name: "Web or Mobile App",
    description:
      "It’s im­pos­si­ble to great at every­thing. Avoid di­lut­ing your fo­cus by con­cen­trat­ing on a sin­gle plat­form for your MVP. The best part, you get to choose what that plat­form is!",
    icon: DeviceMobileIcon,
  },
  {
    name: "AWS Environment Setup",
    description:
      "Amazon Web Services is the worlds largest cloud host­ing provider. After cre­at­ing your ac­count a spe­cial­ist DevOps en­gi­neer will setup your en­vi­ron­ment. This process would nor­mally cost tens of thou­sands of dol­lars.",
    icon: ServerIcon,
  },
  {
    name: "Production Release",
    description:
      "A pro­duc­tion re­lease means your ap­pli­ca­tion is live! It has all the ben­e­fits of go­ing through test­ing en­vi­ron­ments and gives you a clean slate to start test­ing your user base.",
    icon: RefreshIcon,
  },
  {
    name: "Hi-fi pro­to­type",
    description:
      "A high-fi­delity (hi-fi) pro­to­type is your ul­ti­mate safety net. By cre­at­ing an as­set that shows ex­actly what your app looks like and how users in­ter­act with it, you can re­move am­bi­gu­ity and mit­i­gate risks be­fore start­ing de­vel­op­ment.",
    icon: DesktopComputerIcon,
  },
  //   {
  //     name: "3 Months of Product Success Consults",
  //     description:
  //       "Just be­cause some­thing is built, does­n’t mean it will suc­ceed. A cus­tomer suc­cess con­sul­tant will walk you through ac­tiv­i­ties and plat­forms that can help you find your prod­uct mar­ket fit.",
  //     icon: MailIcon,
  //   },
];

export default function Example() {
  return (
    <div className="bg-gray-50 overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
          />
        </svg>

        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div className="lg:col-span-1">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Included in a MVP Engagement
            </h2>
          </div>
          <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
            {features.map((feature) => (
              <div key={feature.name}>
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-600 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
