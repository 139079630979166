import {
  BeakerIcon,
  CashIcon,
  DocumentReportIcon,
} from "@heroicons/react/outline";

const supportLinks = [
  {
    name: "Keep your costs down with­out sac­ri­fic­ing con­trol.",
    href: "#",
    description:
      "Cost can be a pro­hib­i­tive fac­tor when de­cid­ing whether to build cus­tom soft­ware or im­ple­ment an off the shelf sys­tem. The MVP en­gage­ment al­lows you to keep your costs down while gain­ing all the ben­e­fits of own­ing the in­tel­lec­tual prop­erty of your ap­pli­ca­tion.",
    icon: CashIcon,
  },
  {
    name: "Scale your busi­ness fast & grow your rev­enue.",
    href: "#",
    description:
      "With cus­tom soft­ware you can scale up know­ing you have an ap­pli­ca­tion that can scale with you. Whether that means up­grad­ing those time-con­sum­ing spread­sheets or mon­etis­ing a new prod­uct and earn­ing that all im­por­tant re­cur­ring rev­enue, an MVP can help you reach your goals.",
    icon: DocumentReportIcon,
  },
  {
    name: "Validate with user test­ing.",
    href: "#",
    description:
      "User test­ing is com­pleted dur­ing the 5 day work­shop us­ing a hi-fi, in­ter­ac­tive pro­to­type. We send this pro­to­type out to a se­lec­tion of your users and gather as much feed­back as pos­si­ble. This feed­back is then col­lated to in­form the fi­nal look and feel of the MVP.",
    icon: BeakerIcon,
  },
];

export default function Header() {
  return (
    <>
      <div className="bg-gray-50">
        {/* Header */}
        <div className="relative pb-32 bg-gray-800">
          <div className="absolute inset-0">
            <img
              className="w-full h-full object-cover"
              src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100"
              alt=""
            />
            <div
              className="absolute inset-0 bg-gray-800 mix-blend-multiply"
              aria-hidden="true"
            />
          </div>
          <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">
              Minimum Viable Product
            </h1>

            <p className="mt-6 max-w-3xl text-xl text-gray-300">
              A min­i­mum vi­able prod­uct is a first ver­sion of a prod­uct
              with just enough fea­tures for users to pro­vide con­struc­tive
              feed­back for fu­ture de­vel­op­ment.
            </p>
            <p className="mt-6 max-w-3xl text-xl text-gray-300">
              Create and launch your first vi­able prod­uct for a fixed price,
              in 6 weeks.
            </p>
          </div>
        </div>

        {/* Overlapping cards */}
        <section
          className="-mt-32 max-w-7xl mx-auto relative z-10 pb-16 px-4 sm:px-6 lg:px-8"
          aria-labelledby="contact-heading"
        >
          <h2 className="sr-only" id="contact-heading">
            Contact us
          </h2>
          <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
            {supportLinks.map((link) => (
              <div
                key={link.name}
                className="flex flex-col bg-white rounded-2xl shadow-xl"
              >
                <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                  <div className="absolute top-0 p-5 inline-block bg-gray-700 rounded-xl shadow-lg transform -translate-y-1/2">
                    <link.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <h3 className="text-xl font-medium text-gray-900">
                    {link.name}
                  </h3>
                  <p className="mt-4 text-base text-gray-500">
                    {link.description}
                  </p>
                </div>
                {/* <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                    <a
                      href={link.href}
                      className="text-base font-medium text-indigo-700 hover:text-indigo-600"
                    >
                      Contact us<span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div> */}
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}
