/* This example requires Tailwind CSS v2.0+ */
import { ArrowCircleRightIcon } from "@heroicons/react/outline";
const features = [
  {
    name: "Design Prototypes",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
        />
      </svg>
    ),
    description:
      "Create an early working version of your product, experience or idea faster with rapid design prototyping.",
  },
  {
    name: "Web & Mobile Applications",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      </svg>
    ),
    description:
      "Develop interactive web & mobile apps like CRMs, management tools, dashboards or entire platforms.",
  },
  // {
  //   name: "Mobile Applications",
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       className="h-6 w-6"
  //       fill="none"
  //       viewBox="0 0 24 24"
  //       stroke="currentColor"
  //     >
  //       <path
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth={2}
  //         d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
  //       />
  //     </svg>
  //   ),
  //   description:
  //     "Launch a new mobile applications using leading technologies like Facebook's React Native.",
  // },
  {
    name: "Marketplaces",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
        />
      </svg>
    ),
    description:
      "Connect two, three or more types of users with a marketplace customized for your unique vertical.",
  },
  {
    name: "HIPAA Compliant Apps",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
        />
      </svg>
    ),
    description:
      "Ensure your sensitive applications are built with proper security, logging and other HIPAA best practices.",
  },
  {
    name: "SaaS Products",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        />
      </svg>
    ),
    description:
      "Deliver a product that capture recurring-revenue through subscriptions.",
  },
  {
    name: "Minimum Viable Products",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
        />
      </svg>
    ),
    description:
      "Get your MVP launched the right way, the first time. Don’t become another failed startup statistic. ",
  },
  {
    name: "Internal Tools",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
        />
      </svg>
    ),
    description:
      "Need custom software for your internal team? Don’t want to build and manage it in-house? We can help.",
  },
  {
    name: "Support",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
    ),
    description:
      "Maintaining and con­tin­u­ously de­vel­op­ing your ap­p is cru­cial. Post-launch, we can tend to it long-term.",
  },
];

export default function Services() {
  return (
    <div className="bg-gray-900" id="services" style={{ zIndex: -1 }}>
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white tracking-tight">
          Our Services
        </h2>
        <p className="mt-4 max-w-3xl text-lg text-gray-200">
          Working with Paper Clouds is the faster, simpler, way to bring a new
          software product to market. We balance business requirements with
          creative technologies to build robust yet beautiful experiences.{" "}
        </p>
        <a
          className="mt-4 max-w-3xl text-lg text-indigo-300"
          style={{ display: "inline-block" }}
          href={`${window.location.origin}/services`}
        >
          Learn more{" "}
          <ArrowCircleRightIcon
            className="max-w-3xl text-lg text-indigo-300"
            style={{
              height: 17,
              display: "inline",
              position: "relative",
              top: -1,
            }}
          />
        </a>
        <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name}>
              <div>
                <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                  <div className="h-6 w-6 text-white" aria-hidden="true">
                    {feature.icon}
                  </div>
                </span>
              </div>
              <div className="mt-6">
                <h3 className="text-lg font-medium text-white">
                  {feature.name}
                </h3>
                <p className="mt-2 text-base text-gray-200">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

// export default function Features() {
//   return (
//     <div className="bg-white">
//       <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
//         <div className="max-w-3xl mx-auto text-center">
//           <h2 className="text-3xl font-extrabold text-gray-900">
//             Our Services
//           </h2>
//           <p className="mt-4 text-lg text-gray-500">
//             Working with Paper Clouds is the faster, simpler, way to bring a new
//             software product to market. We balance business requirements with
//             creative technologies to build robust yet beautiful experiences.
//             We’re comfortable working with all kinds of organizations, from
//             startups and non-profits to tech-forward companies.
//           </p>
//         </div>
//         <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
//           {features.map((feature) => (
//             <div key={feature.name} className="relative">
//               <dt>
//                 <CheckIcon
//                   className="absolute h-6 w-6 text-green-500"
//                   aria-hidden="true"
//                 />
//                 <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
//                   {feature.name}
//                 </p>
//               </dt>
//               <dd className="mt-2 ml-9 text-base text-gray-500">
//                 {feature.description}
//               </dd>
//             </div>
//           ))}
//         </dl>
//       </div>
//     </div>
//   );
// }
