import ContactUs from "../../components/ContactUs";
import { useEffect } from "react";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";
import HeaderNav from "../../components/HeaderNav";
import Header from "./Header";

export default function WebAndBranding() {
  const { pathname } = useLocation();
  useEffect(() => {
    /* settimeout make sure this run after components have rendered. This will help fixing bug for some views where scroll to top not working perfectly */
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, [pathname]);

  return (
    <>
      <HeaderNav />
      <Header />
      <ContactUs />
      <Footer />
    </>
  );
}
