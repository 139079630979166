import reactPNG from './logos/react.png';
import nodePNG from './logos/node.svg';
import graphqlPNG from './logos/graphql.png';
import mongoSVG from './logos/mongo.svg';
import rnPNG from './logos/rn.png';
import tsPNG from './logos/ts.png';
import netlifyPNG from './logos/netlify.png';
import awsPNG from './logos/aws.png';

const metrics: any[] = [
  {
    id: 1,
    stat: 'React.js',
    logo: reactPNG,
    emphasis: '',
    rest: 'use laoreet amet lacus nibh integer quis.',
  },
  {
    id: 2,
    stat: 'Node.js',
    logo: nodePNG,
    height: 64,
    width: 160,
    offsetTop: -20,
    emphasis: '',
    rest: 'lacus nibh integer quis.',
  },
  {
    id: 3,
    stat: 'MongoDB',
    logo: mongoSVG,
    height: 84,
    width: 160,
    emphasis: '',
    rest: 'laoreet amet lacus nibh integer quis.',
  },
  {
    id: 4,
    stat: 'GraphQL',
    logo: graphqlPNG,
    offsetTop: -5,
    height: 84,
    width: 160,
    emphasis: ' ',
    rest: 'lacus nibh integer quis.',
  },
  {
    id: 5,
    stat: 'React Native',
    logo: rnPNG,
    height: 84,
    width: 180,
    emphasis: ' ',
    rest: 'lacus nibh integer quis.',
  },
  {
    id: 6,
    stat: 'TypeScript',
    logo: tsPNG,
    height: 72,
    width: 170,
    offsetTop: -10,
    emphasis: ' ',
    rest: 'lacus nibh integer quis.',
  },
  {
    id: 7,
    stat: 'AWS',
    emphasis: ' ',
    logo: awsPNG,
    offsetTop: -10,
    rest: 'lacus nibh integer quis.',
  },
  {
    id: 8,
    stat: 'Netlify',
    emphasis: ' ',
    logo: netlifyPNG,
    rest: 'lacus nibh integer quis.',
  },
];

export default function Technologies() {
  return (
    <div className="relative bg-gray-900" id="technologies">
      <div className="h-80 w-full absolute bottom-0 xl:inset-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-2">
          <div className="h-full xl:relative xl:col-start-2">
            <img
              className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
              src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
              alt="People working on laptops"
            />
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
            />
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
        <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
          <h2 className="text-sm font-semibold text-gray-400 tracking-wide uppercase">
            Built for growth
          </h2>
          <p className="mt-3 text-3xl font-extrabold text-white">
            Technologies that you can grow with.
          </p>
          <p className="mt-5 text-lg text-gray-300">
            We build your product using the same technologies as leading
            companies like AirBnb, Facebook, Instagram, Netflix, New York Times,
            Walmart, Uber and Paypal.
          </p>
          <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
            {metrics.map((item) => (
              <p key={item.id}>
                {item.logo ? (
                  <img
                    src={item.logo}
                    alt="logo"
                    width="auto"
                    style={{
                      marginBottom: 16,
                      maxHeight: item.height || 60,
                      maxWidth: item.width || 120,
                      position: 'relative',
                      top: item.offsetTop || 0,
                    }}
                  />
                ) : (
                  <span className="block text-2xl font-bold text-white">
                    {item.stat}
                  </span>
                )}

                <span className="mt-1 block text-base text-gray-300">
                  <span className="font-medium text-white">
                    {item.emphasis}
                  </span>{' '}
                  {/* {item.rest} */}
                </span>
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
