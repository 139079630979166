// /* This example requires Tailwind CSS v2.0+ */
// export default function HealthCTA() {
//   return (
//     <div className="bg-gray-100">
//       <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
//         <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
//           <span className="block">Need healthcare software?</span>
//           <span className="block text-gray-600">
//             We can help with HIPAA-compliant apps
//           </span>
//         </h2>
//         <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
//           <div className="inline-flex rounded-md shadow">
//             <a
//               href="#"
//               className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700"
//             >
//               Learn more
//             </a>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

export default function Example() {
  return (
    <div className="bg-gray-700">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">Need healthcare software?</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-gray-300">
          We can help with HIPAA-compliant applications
        </p>
        <a
          href={`${window.location.origin}/healthcare`}
          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 sm:w-auto"
        >
          Learn more
        </a>
      </div>
    </div>
  );
}
