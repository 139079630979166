import AppRoutes from './pages/index';
import client from './ApolloClient/index';
import {ApolloProvider} from '@apollo/client';
import SnackbarProvider from 'react-simple-snackbar';

function App() {
  return (
    <SnackbarProvider>
      <ApolloProvider client={client}>
        <AppRoutes />
      </ApolloProvider>
    </SnackbarProvider>
  );
}

export default App;
