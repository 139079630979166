/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  BeakerIcon,
  CursorClickIcon,
  SparklesIcon,
  HeartIcon,
  RefreshIcon,
  SupportIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";

export const solutions: any[] = [
  {
    name: "Proof of Concept",
    description:
      "For projects that need in­vest­ment be­fore full-scale de­vel­op­ment, or for shorter ex­per­i­ments.",
    href: `${window.location.origin}/services/poc`,
    icon: BeakerIcon,
  },
  {
    name: "Minimum Viable Product",
    description:
      "MVPs are a great starter op­tion for busi­nesses who need be­spoke soft­ware but want to start small. ",
    href: `${window.location.origin}/services/minimum-viable-product`,
    icon: CursorClickIcon,
  },
  {
    name: "Product Development",
    description:
      "For busi­nesses that have the bud­get and time in­vest­ment nec­es­sary to tackle more com­plex and am­bi­tious soft­ware needs.",
    href: `${window.location.origin}/services/product-development`,
    icon: RefreshIcon,
  },
  {
    name: "Websites and Branding",
    description:
      "If your busi­ness needs help get­ting started on set­ting up its brand and reach­ing out to cus­tomers, this is the best op­tion for you.",
    href: `${window.location.origin}/services/web-and-branding`,
    icon: SparklesIcon,
  },
  {
    name: "Healthcare Solutions",
    description:
      "We can help with telemedicine, dashboards, HIPAA compliant applications and more.",
    href: `${window.location.origin}/healthcare`,
    icon: HeartIcon,
  },
  {
    name: "Support",
    description:
      "Our application sup­port al­lows you to en­trust us with the main­te­nance of your soft­ware once it has been re­leased.",
    href: `${window.location.origin}/services/support`,
    icon: SupportIcon,
  },
];

export const SolutionItem = ({ solution }: any) => (
  <a
    key={solution.name}
    href={solution.href}
    className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
  >
    <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gray-500 text-white">
      <solution.icon className="h-6 w-6" aria-hidden="true" />
    </div>
    <div className="ml-4 text-base font-medium text-gray-900">
      {solution.name}
    </div>
  </a>
);

export default function Solutions({ showConsulting = true }) {
  return (
    <Popover className="relative" style={{ display: "inline" }}>
      {({ open }) => (
        <>
          <Popover.Button
            className={
              open
                ? "text-gray-900 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                : "text-gray-500 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            }
          >
            <span>Solutions</span>
            <ChevronDownIcon
              className={
                open
                  ? "text-gray-600 ml-2 h-5 w-5 group-hover:text-gray-500"
                  : "text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500"
              }
              aria-hidden="true"
            />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                  {solutions.map((solution) => (
                    <a
                      key={solution.name}
                      href={solution.href}
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-gray-600 text-white sm:h-12 sm:w-12">
                        <solution.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">
                          {solution.name}
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          {solution.description}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
                {showConsulting && (
                  <div className="p-5 bg-gray-50 sm:p-8">
                    <a
                      href={window.location.origin}
                      className="-m-3 p-3 flow-root rounded-md hover:bg-gray-100"
                    >
                      <div className="flex items-center">
                        <div className="text-base font-medium text-gray-900">
                          Custom Consulting
                        </div>
                        <span className="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-indigo-100 text-indigo-800">
                          New
                        </span>
                      </div>
                      <p className="mt-1 text-sm text-gray-500">
                        Our do­main ex­perts un­der­stand that every prob­lem is
                        unique and will work closely with you to con­struct a
                        so­lu­tion that de­liv­ers the great­est pos­si­ble
                        ben­e­fit to your busi­ness.
                      </p>
                    </a>
                  </div>
                )}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
