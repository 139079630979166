import {
  DocumentTextIcon,
  PresentationChartLineIcon,
  ScaleIcon,
} from "@heroicons/react/outline";

const supportLinks = [
  {
    name: "Low risk and light­ning fast.",
    href: "#",
    description:
      "POCs are time-fixed, com­pris­ing of a 3-day scop­ing work­shop and 2 weeks of de­vel­op­ment. This sets your bud­get from the get go and al­lows you to val­i­date your idea with users quickly. A POC is a low-risk way of let­ting in­vestors know you can ex­e­cute on your vi­sion.",
    icon: ScaleIcon,
  },
  {
    name: "In the dri­ver’s seat.",
    href: "#",
    description:
      "At the con­clu­sion of POC de­vel­op­ment you have the op­tion to make small changes to the ap­pli­ca­tion via our ser­vice desk. Bigger changes may ne­ces­si­tate an MVP or Product Development engagement, but you have full con­trol over how much you want to add to the app and when.",
    icon: PresentationChartLineIcon,
  },
  {
    name: "Project Artefacts.",
    href: "#",
    description:
      "Aside from your POC ap­pli­ca­tion, our de­sign­ers and de­vel­op­ers will also cre­ate user flow di­a­grams, a back­log and UI de­signs, along with any other arte­facts in­ci­den­tal to the work­shop. These arte­facts can in­form any fu­ture de­vel­op­ment you may choose to do.",
    icon: DocumentTextIcon,
  },
];

export default function Header() {
  return (
    <>
      <div className="bg-gray-50">
        {/* Header */}
        <div className="relative pb-32 bg-gray-800">
          <div className="absolute inset-0">
            <img
              className="w-full h-full object-cover"
              src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100"
              alt=""
            />
            <div
              className="absolute inset-0 bg-gray-800 mix-blend-multiply"
              aria-hidden="true"
            />
          </div>
          <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">
              Proof of Concept
            </h1>

            <p className="mt-6 max-w-3xl text-xl text-gray-300">
              A proof of con­cept is an ex­per­i­ment or pi­lot pro­ject to
              demon­strate fea­si­bil­ity of a de­sign con­cept or a busi­ness
              pro­posal.
            </p>
            <p className="mt-6 max-w-3xl text-xl text-gray-300">
              Build, test and val­i­date your POC for a fixed price in 3 weeks.
            </p>
          </div>
        </div>

        {/* Overlapping cards */}
        <section
          className="-mt-32 max-w-7xl mx-auto relative z-10 pb-16 px-4 sm:px-6 lg:px-8"
          aria-labelledby="contact-heading"
        >
          <h2 className="sr-only" id="contact-heading">
            Contact us
          </h2>
          <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
            {supportLinks.map((link) => (
              <div
                key={link.name}
                className="flex flex-col bg-white rounded-2xl shadow-xl"
              >
                <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                  <div className="absolute top-0 p-5 inline-block bg-gray-700 rounded-xl shadow-lg transform -translate-y-1/2">
                    <link.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <h3 className="text-xl font-medium text-gray-900">
                    {link.name}
                  </h3>
                  <p className="mt-4 text-base text-gray-500">
                    {link.description}
                  </p>
                </div>
                {/* <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                    <a
                      href={link.href}
                      className="text-base font-medium text-indigo-700 hover:text-indigo-600"
                    >
                      Contact us<span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div> */}
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}
