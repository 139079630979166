export default function Hero() {
  return (
    <>
      <div className="relative bg-white overflow-hidden">
        <div className="relative pt-6 pb-16 sm:pb-24">
          <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Services that </span>{" "}
                <span className="block text-gray-900 xl:inline">deliver </span>
                <span className="block xl:inline">outcomes</span>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Every pro­ject and every busi­ness is unique, so it would be
                ridicu­lous to have a sin­gle en­gage­ment model. We of­fer
                sev­eral ways to ap­proach soft­ware de­vel­op­ment.
              </p>
              {/* <p className="mt-3 max-w-md mx-auto text-base text-gray-800 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Every pro­ject and every busi­ness is unique, so it would be
              ridicu­lous to have a sin­gle en­gage­ment model. There are ﬁve
              ser­vice mod­els that are tai­lored to suit your pro­ject. A Proof
              of Concept is ideal for ideas which need to be tested fast. MVP’s
              are small prod­ucts with a vari­able scope. Product Development is
              best for com­plex prob­lems which re­quire a more com­pre­hen­sive
              so­lu­tion. Consultations un­pack and in­ves­ti­gate prob­lems,
              al­low­ing a team to iden­tify what your best path for­ward is.
              Finally, Websites and Branding is avail­able for beau­ti­fully
              de­signed mar­ket­ing sites and brands that will help you
              con­vert.
            </p> */}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
