import { useMutation } from "@apollo/client";
import { useState } from "react";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import SUBMIT_CONTACT_FORM from "../../ApolloClient/Mutations/submitContactForm";
import config from "../../lib/config/index";

export default function Example() {
  const [submitContactForm, { loading }] = useMutation(SUBMIT_CONTACT_FORM);
  const [success, setSuccess] = useState<boolean>(false);
  const [values, setValues] = useState<{
    fullName?: string;
    email?: string;
    phone?: string;
    message?: string;
  }>({
    fullName: undefined,
    email: undefined,
    phone: undefined,
    message: undefined,
  });
  const [errors, setErrors] = useState<{
    fullName?: string;
    email?: string;
    phone?: string;
    message?: string;
  }>({
    fullName: undefined,
    email: undefined,
    phone: undefined,
    message: undefined,
  });

  const handleOnSubmit = async (e: any) => {
    try {
      e.preventDefault();
      setErrors({
        fullName: undefined,
        email: undefined,
        phone: undefined,
        message: undefined,
      });
      if (!values.fullName) {
        return setErrors({
          ...errors,
          fullName: "Name is required",
        });
      }
      if (!values.email) {
        return setErrors({
          ...errors,
          email: "Email is required",
        });
      }

      let variables = {
        contactForm: {
          phone: values.phone,
          email: values.email,
          message: values.message,
          name: values.fullName,
          product: "foundry41",
        },
      };
      await submitContactForm({
        variables,
      });
      setSuccess(true);
    } catch (err) {
      console.log("error bros");
    }
  };

  return (
    <div className="relative bg-white" id="contact">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Let's connect.
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              We partner with organizations to make important ideas become a
              reality. Let us know a little more about your current situation
              and let’s setup a time to talk.
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Postal address</dt>
                <dd>
                  {/* <p>123 Market St.</p> */}
                  <p>Portsmouth, NH 03801</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">{config.phone}</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">inquiries@paperclouds.co</span>
                </dd>
              </div>
            </dl>
            <p className="mt-6 text-base text-gray-500">
              Looking for careers?{" "}
              <a
                href={`${window.location.origin}/careers`}
                target="_blank"
                rel="noreferrer"
                className="font-medium text-gray-700 underline"
              >
                View all job openings
              </a>
              .
            </p>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            {!success ? (
              <form
                onSubmit={handleOnSubmit}
                className="grid grid-cols-1 gap-y-6"
              >
                <div>
                  <label htmlFor="full_name" className="sr-only">
                    Full name
                  </label>
                  <input
                    type="text"
                    name="full_name"
                    id="full_name"
                    autoComplete="name"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md"
                    placeholder="Full name"
                    onChange={(e) =>
                      setValues({ ...values, fullName: e.target.value })
                    }
                  />
                  {errors.fullName && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {errors.fullName}
                    </p>
                  )}
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md"
                    placeholder="Email"
                    onChange={(e) =>
                      setValues({ ...values, email: e.target.value })
                    }
                  />
                  {errors.email && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {errors.email}
                    </p>
                  )}
                </div>
                <div>
                  <label htmlFor="phone" className="sr-only">
                    Phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md"
                    placeholder="Phone"
                    onChange={(e) =>
                      setValues({ ...values, phone: e.target.value })
                    }
                  />
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md"
                    placeholder="Message"
                    defaultValue={""}
                    onChange={(e) =>
                      setValues({ ...values, message: e.target.value })
                    }
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    disabled={loading}
                    className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Submit
                  </button>
                </div>
              </form>
            ) : (
              <div>
                We received your message and somebody will be in touch with you
                shortly.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
