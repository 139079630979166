/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import logoIconSVG from "../../lib/media/logo.svg";
import Solutions, { SolutionItem, solutions } from "./Solutions";

const resources = [
  {
    name: "Services",
    description: "Services that deliver outcomes.",
    href: `${window.location.origin}/services`,
  },
  {
    name: "Technologies",
    description: "Technologies that you can grow with.",
    href: `${window.location.origin}#technologies`,
  },
  {
    name: "Contact Us",
    description: "Have more questions? Let's connect.",
    href: `${window.location.origin}#contact`,
  },
  {
    name: "Careers",
    description: "Help us build cool stuff.",
    href: `${window.location.origin}/careers`,
  },
];

const MobileMenu = () => (
  <Transition
    as={Fragment}
    enter="duration-200 ease-out"
    enterFrom="opacity-0 scale-95"
    enterTo="opacity-100 scale-100"
    leave="duration-100 ease-in"
    leaveFrom="opacity-100 scale-100"
    leaveTo="opacity-0 scale-95"
  >
    <Popover.Panel
      focus
      className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
    >
      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
        <div className="pt-5 pb-6 px-5">
          <div className="flex items-center justify-between">
            <div>
              <img className="h-6 w-auto" src={logoIconSVG} alt="Workflow" />
            </div>
            <div className="-mr-2">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
                <span className="sr-only">Close menu</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          </div>
          <div className="mt-6">
            <nav className="grid grid-cols-1 gap-7">
              {solutions.map((solution) => (
                <SolutionItem key={solution.name} solution={solution} />
              ))}
            </nav>
          </div>
        </div>
        <div className="py-6 px-5">
          <div className="grid grid-cols-2 gap-4">
            <a
              href={window.location.origin}
              className="text-base font-medium text-gray-900 hover:text-gray-700"
            >
              Home
            </a>
            {resources.map((resource, i) => (
              <a
                key={i}
                href={resource.href}
                className="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                {resource.name}
              </a>
            ))}
          </div>
          <div className="mt-6">
            <p className="mt-6 text-center text-base font-medium text-gray-500">
              <a
                href={`${window.location.origin}#contact`}
                className="text-gray-600 hover:text-gray-500"
              >
                Hire Us
              </a>
            </p>
          </div>
        </div>
      </div>
    </Popover.Panel>
  </Transition>
);
// const MobileMenu = () => (
//   <Transition
//     as={Fragment}
//     enter="duration-200 ease-out"
//     enterFrom="opacity-0 scale-95"
//     enterTo="opacity-100 scale-100"
//     leave="duration-100 ease-in"
//     leaveFrom="opacity-100 scale-100"
//     leaveTo="opacity-0 scale-95"
//   >
//     <Popover.Panel
//       focus
//       className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
//     >
//       <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
//         <div className="pt-5 pb-6 px-5">
//           <div className="flex items-center justify-between">
//             <div>
//               <img className="h-4 w-auto" src={logoIconSVG} alt="Workflow" />
//             </div>
//             <div className="-mr-2">
//               <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
//                 <span className="sr-only">Close menu</span>
//                 <XIcon className="h-6 w-6" aria-hidden="true" />
//               </Popover.Button>
//             </div>
//           </div>
//           <div className="mt-6">
//             <MobileSolutions />
//           </div>
//         </div>
//         <div className="py-6 px-5">
//           <div className="grid grid-cols-2 gap-4">
//             <a
//               href={window.location.origin}
//               className="text-base font-medium text-gray-900 hover:text-gray-700"
//             >
//               Home
//             </a>
//             {resources.map((resource, i) => (
//               <a
//                 key={i}
//                 href={resource.href}
//                 className="text-base font-medium text-gray-900 hover:text-gray-700"
//               >
//                 {resource.name}
//               </a>
//             ))}
//           </div>
//           <div className="mt-6">
//             <p className="mt-6 text-center text-base font-medium text-gray-500">
//               <a
//                 href={`${window.location.origin}#contact`}
//                 className="text-gray-600 hover:text-gray-500"
//               >
//                 Hire Us
//               </a>
//             </p>
//           </div>
//         </div>
//       </div>
//     </Popover.Panel>
//   </Transition>
// );

const More = () => (
  <Popover className="relative">
    {({ open }) => (
      <>
        <Popover.Button
          className={
            open
              ? "text-gray-900 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              : "text-gray-500 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          }
        >
          <span>More</span>
          <ChevronDownIcon
            className={
              open
                ? "text-gray-600 ml-2 h-5 w-5 group-hover:text-gray-500"
                : "text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500"
            }
            aria-hidden="true"
          />
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                {resources.map((resource) => (
                  <a
                    key={resource.name}
                    href={resource.href}
                    className="-m-3 p-3 block rounded-md hover:bg-gray-50"
                  >
                    <p className="text-base font-medium text-gray-900">
                      {resource.name}
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      {resource.description}
                    </p>
                  </a>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>
);

const HireUsBtn = () => (
  <span className="inline-flex rounded-md shadow">
    <a
      href={`${window.location.origin}#contact`}
      className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-gray-900 bg-gray-300 hover:bg-gray-50"
    >
      Hire us
    </a>
  </span>
);

export default function Example() {
  return (
    <Popover className="relative bg-white" style={{ zIndex: 10000 }}>
      <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <a href={window.location.origin}>
            <span className="sr-only">PaperClouds</span>
            <img className="h-6 w-auto sm:h-8" src={logoIconSVG} alt="" />
          </a>
        </div>
        <div className="-mr-2 -my-2 md:hidden">
          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500">
            <span className="sr-only">Open menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>
        <Popover.Group as="nav" className="hidden md:flex space-x-10">
          <a
            href={`${window.location.origin}`}
            className="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Home
          </a>
          <Solutions />
          <More />
        </Popover.Group>
        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <HireUsBtn />
        </div>
      </div>
      <MobileMenu />
    </Popover>
  );
}
