// TOP LEVEL IMPORTS
import gql from 'graphql-tag';

export default gql`
  mutation submitContactForm($contactForm: ContactForm) {
    submitContactForm(contactForm: $contactForm) {
      success
    }
  }
`;
