/* This example requires Tailwind CSS v2.0+ */
import {
  UserGroupIcon,
  RefreshIcon,
  ServerIcon,
  CodeIcon,
  CursorClickIcon,
  CloudUploadIcon,
  DeviceMobileIcon,
  FlagIcon,
  RssIcon,
} from "@heroicons/react/outline";

const features = [
  {
    name: "5 days de­sign + 2-4 Weeks build",
    description: (
      <>
        <dd className="mt-2 text-base text-gray-500">
          Our base level of­fer­ing al­lo­cates five days to scop­ing your
          web­site and up to 4 weeks for build. This means that prior to
          en­gag­ing with us you will ei­ther need to have some copy­writ­ing
          and con­tent ready to go or be pre­pared to pause scope be­fore the
          pro­to­type can be fi­nalised.
        </dd>
        <dd className="mt-2 text-base text-gray-500">
          Bear in mind that paus­ing scopes means that you may not be able to
          re­sume scop­ing when it is next con­ve­nient for you, as the prod­uct
          de­signer may have been as­signed to other client work in the
          mean­time. There may be a de­lay be­fore they can at­tend to your
          web­site. Therefore, it is highly rec­om­mended that you ei­ther
          de­vise the con­tent be­fore en­gag­ing with PaperClouds or pur­chase
          the con­tent de­vel­op­ment add-on.
        </dd>
      </>
    ),
    icon: UserGroupIcon,
  },
  {
    name: "Source Code",
    description: (
      <>
        <dd className="mt-2 text-base text-gray-500">
          You own the source code for the web­site, which means that once the
          site has been de­ployed you will be able to edit every part of the
          web­site at your dis­cre­tion. This could be any­thing from chang­ing
          con­tent to al­ter­ing styles and scripts. We build our web­site with
          industry leading technologies that any developer will be able to tweak
          in the future.
        </dd>
        <dd className="mt-2 text-base text-gray-500">
          Both of these lan­guages are in­dus­try-stan­dard for web de­sign and
          have plenty of doc­u­men­ta­tion on­line to help you and your team
          should you wish to edit the code di­rectly. If not, our sup­port
          ser­vices are al­ways on hand to help.
        </dd>
      </>
    ),
    icon: CodeIcon,
  },
  {
    name: "Prototype",
    description: (
      <>
        <dd className="mt-2 text-base text-gray-500">
          The pro­to­type you re­ceive dur­ing the de­sign phase is very
          sim­i­lar to the hi-fi pro­to­types you will have seen dur­ing app
          de­vel­op­ment. The pro­to­type will closely re­sem­ble the in­tended
          look and feel of the web­site. The more fi­nalised con­tent you have
          on hand prior to fi­nal­i­sa­tion, the eas­ier it will be for our
          de­sign­ers to de­sign a site which ac­com­mo­dates the copy.
        </dd>
        <dd className="mt-2 text-base text-gray-500">
          The pro­to­type will de­scribe all pages, links and sec­tions found on
          the web­site. Your web­site can­not move into the build phase un­til
          you are happy with the pro­to­type, with the pro­viso that our
          de­sign­ers will build us­ing the hi-fis as a source of truth.
        </dd>
      </>
    ),
    icon: CursorClickIcon,
  },
  {
    name: "Hosting Setup",
    description: (
      <>
        <dd className="mt-2 text-base text-gray-500">
          We can de­ploy your web­site to your choice of host­ing provider or
          give you our best rec­om­men­da­tion. We gen­er­ally build our
          web­sites us­ing tools like Jekyll and Netlify, which al­low for quick
          gen­er­a­tion of sta­tic web­sites. More com­plex con­tent
          man­age­ment sys­tems may re­quire a dif­fer­ent setup, which will
          need to be ad­dressed dur­ing scope.
        </dd>
        <dd className="mt-2 text-base text-gray-500">
          At the end of web­site de­vel­op­ment you will have full ac­cess to
          your site, in­clud­ing the source code. However, not every­one is
          com­fort­able edit­ing web­sites at this level, which is why we can
          in­cor­po­rate con­tent man­age­ment into your web­site. Due to the
          way CMS’ are built, sec­tions which will re­quire ed­its by mem­bers
          of your team (such as the blog) will need to be iden­ti­fied ahead of
          time so the de­signer can pro­gram that func­tion­al­ity into the
          web­site. You can al­ways re­turn at any point to add CMS
          func­tion­al­ity to other pages or sec­tions.
        </dd>
      </>
    ),
    icon: ServerIcon,
  },
  {
    name: "Content Management System",
    description:
      "We pro­vide a cus­tom CMS for mar­ket­ing web­sites. The CMS al­lows you to en­ter the site’s ad­min­is­tra­tive back­end and add con­tent with­out hav­ing to touch the source code. Due to the be­spoke na­ture of a CMS, sec­tions or pages which need to be ed­itable in the back­end should be in­di­cated to the de­signer prior to com­menc­ing build. Some com­mon ar­eas which typ­i­cally have CMS in­te­gra­tion in­clude blog pages, ar­ti­cles and pro­ject/​port­fo­lio en­tries.",
    icon: CloudUploadIcon,
  },
  {
    name: "Mobile Responsive",
    description: (
      <>
        <dd className="mt-2 text-base text-gray-500">
          Your web­site will be mo­bile re­spon­sive such that it will have
          lay­outs for large, medium and small break­points. This should
          ac­com­mo­date for most de­vices, how­ever due to the dif­fer­ences
          be­tween plat­forms, there may be some small changes on rarer sizes.
          Every ef­fort will be made to keep the look and feel of your web­site
          ac­ces­si­ble and brand-co­he­sive re­gard­less of the plat­form it is
          be­ing viewed on.
        </dd>
        <dd className="mt-2 text-base text-gray-500">
          Depending on your tar­get au­di­ence, you may wish to de­sign
          mo­bile-first (that is, de­sign­ing your web­site pri­mar­ily for use
          on mo­bile de­vices). We are happy to pro­vide our de­signs based
          pri­mar­ily on mo­bile, as long as we are alerted to this ahead of
          time.
        </dd>
      </>
    ),
    icon: DeviceMobileIcon,
  },
  {
    name: "Deployment",
    description: (
      <>
        <dd className="mt-2 text-base text-gray-500">
          We can de­ploy your new mar­ket­ing web­site to the host server of
          your choice. The source code for your web­site is held within a
          se­cure Git repos­i­tory. This al­lows for the web­site to be edited
          with­out chang­ing the live site.
        </dd>
        <dd className="mt-2 text-base text-gray-500">
          Using tools like Forestry, com­peted work is then pushed up to the
          live site. You are re­spon­si­ble for host­ing, how­ever our team of
          de­vel­op­ers can give you ad­vice on what sort of host­ing would best
          suit your needs.
        </dd>
      </>
    ),
    icon: RefreshIcon,
  },
  {
    name: "Intellectual Property",
    description: (
      <>
        <dd className="mt-2 text-base text-gray-500">
          As with the source code, you own the in­tel­lec­tual prop­erty of your
          web­site, such as text, im­ages and orig­i­nal videos. PaperClouds may
          ask to dis­play your web­site on its own mar­ket­ing web­site or
          port­fo­lio but we will never take credit for your work.
        </dd>
      </>
    ),
    icon: FlagIcon,
  },
  {
    name: "Marketing Funnel",
    description: (
      <>
        <dd className="mt-2 text-base text-gray-500">
          Every web­site needs to con­vert. A mar­ket­ing fun­nel is setup to
          in­crease con­ver­sion rates. There are three key com­po­nents, the
          first of which is a high value con­tent of­fer. This is some­thing
          that your vis­i­tors are drawn to­wards. Think about what
          in­for­ma­tion you have that they don’t - it could be a guide, a book
          or even a video. The sec­ond com­po­nent is a land­ing page
          op­ti­mised to con­vert vis­i­tors.
        </dd>
        <dd className="mt-2 text-base text-gray-500">
          The fi­nal com­po­nent of a mar­ket­ing fun­nel is an of­fer that
          ex­tracts whether your lead has a need for your prod­uct or ser­vice.
          This could be a work­shop, a 1-on-1 ses­sion or even a we­bi­nar. The
          goal is to let your leads progress them­selves down your sales
          fun­nel. Part of the web­site of­fer­ing is help­ing you cre­ate a
          mar­ket­ing fun­nel that will con­vert your site vis­i­tors when
          you’re live.
        </dd>
      </>
    ),
    icon: RssIcon,
  },
  {
    name: "Continuous Integration/Continuous Deployment",
    description: (
      <>
        <dd className="mt-2 text-base text-gray-500">
          Continuous in­te­gra­tion/​con­tin­u­ous de­ploy­ment (CI/CD) is
          im­por­tant in the soft­ware de­vel­op­ment in­dus­try but is
          some­times for­got­ten when build­ing web­sites. With con­tin­u­ous
          in­te­gra­tion it’s sim­ple to re­lease a new ver­sion of your site.
          Netlify, a tool that we utilise dur­ing the build process, can auto
          pub­lish when you make a change. This is in­cred­i­bly pow­er­ful when
          it syncs up with a con­tent man­age­ment sys­tem. It means you don’t
          need to code to get changes live.
        </dd>
        <dd className="mt-2 text-base text-gray-500">
          Continuous de­ploy­ment means that with every de­ploy your web­site is
          au­to­mat­i­cally dis­trib­uted to global in­fra­struc­ture around the
          world. By dis­trib­ut­ing con­tent closer to each web­site vis­i­tor
          through a nearby server, vis­i­tors ex­pe­ri­ence faster page load­ing
          times.
        </dd>
      </>
    ),
    icon: RefreshIcon,
  },
];

export default function Example() {
  return (
    <div className="bg-gray-50 overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
          />
        </svg>

        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div className="lg:col-span-1">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Included with your engagment
            </h2>
          </div>
          <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
            {features.map((feature) => (
              <div key={feature.name}>
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-700 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
