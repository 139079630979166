import {
  // NewspaperIcon,
  // PhoneIcon,
  // SupportIcon,
  CheckIcon,
} from "@heroicons/react/outline";

// const supportLinks = [
//   {
//     name: "Low risk and light­ning fast.",
//     href: "#",
//     description:
//       "POCs are time-fixed, com­pris­ing of a 3-day scop­ing work­shop and 2 weeks of de­vel­op­ment. This sets your bud­get from the get go and al­lows you to val­i­date your idea with users quickly. A POC is a low-risk way of let­ting in­vestors know you can ex­e­cute on your vi­sion.",
//     icon: PhoneIcon,
//   },
//   {
//     name: "In the dri­ver’s seat.",
//     href: "#",
//     description:
//       "At the con­clu­sion of POC de­vel­op­ment you have the op­tion to make small changes to the ap­pli­ca­tion via our ser­vice desk. Bigger changes may ne­ces­si­tate an MVP or Product Development engagement, but you have full con­trol over how much you want to add to the app and when.",
//     icon: SupportIcon,
//   },
//   {
//     name: "Project Artefacts.",
//     href: "#",
//     description:
//       "Aside from your POC ap­pli­ca­tion, our de­sign­ers and de­vel­op­ers will also cre­ate user flow di­a­grams, a back­log and UI de­signs, along with any other arte­facts in­ci­den­tal to the work­shop. These arte­facts can in­form any fu­ture de­vel­op­ment you may choose to do.",
//     icon: NewspaperIcon,
//   },
// ];

const features = [
  {
    name: "Speed is important",
    description:
      "You’re look­ing to move quickly and are ne­go­tiable on the scope of the pro­ject.",
  },
  {
    name: "Technical feasibility is confirmed",
    description:
      "Your so­lu­tion is tech­ni­cally fea­si­ble and you’re ready to com­mer­cialise.",
  },
  {
    name: "Need either web or mobile",
    description:
      "You only need one of ei­ther a web plat­form or a mo­bile app.",
  },
];

export default function IsItRight() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div
          className="max-w-3xl mx-auto text-center"
          style={{ marginBottom: 24 }}
        >
          <h2 className="text-base font-semibold text-indigo-600 uppercase tracking-wide">
            Minimum Viable Product
          </h2>
          <h2 className="text-3xl font-extrabold text-gray-900">
            When is MVP best for you?
          </h2>
          <p className="mt-4 text-lg text-gray-500 mb-24">
            We’d rec­om­mend MVP if you match one of the fol­low­ing points:
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
          {features.map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon
                  className="absolute h-6 w-6 text-green-500"
                  aria-hidden="true"
                />
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}
