import {
  AdjustmentsIcon,
  CursorClickIcon,
  SupportIcon,
} from "@heroicons/react/outline";

const supportLinks = [
  {
    name: "Maintain",
    href: "#",
    description:
      "Any soft­ware ap­pli­ca­tion is go­ing to need on­go­ing main­te­nance. Rather than charge a fixed rate for sup­port, the main­tain of­fer­ing is in­stead cen­tred around a low monthly or an­nual fee.",
    icon: SupportIcon,
  },
  {
    name: "Enhance",
    href: "#",
    description:
      "During Enhance we focus on en­abling the cus­tomer to rapidly re­spond to mar­ket trends and feed­back with­out the need for long de­vel­op­ment cy­cles.",
    icon: AdjustmentsIcon,
  },
  {
    name: "Succeed",
    href: "#",
    description:
      "The Product Success sec­tion of sup­port is about set­ting up a sys­tem that al­lows an or­gan­i­sa­tion to sys­tem­i­cally im­prove their prod­uct us­ing a data-dri­ven ap­proach.",
    icon: CursorClickIcon,
  },
];

export default function Header() {
  return (
    <>
      <div className="bg-gray-50">
        {/* Header */}
        <div className="relative pb-32 bg-gray-800">
          <div className="absolute inset-0">
            <img
              className="w-full h-full object-cover"
              src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&&sat=-100"
              alt=""
            />
            <div
              className="absolute inset-0 bg-gray-800 mix-blend-multiply"
              aria-hidden="true"
            />
          </div>
          <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">
              Support
            </h1>

            <p className="mt-6 max-w-3xl text-xl text-gray-300">
              Software de­vel­op­ment is a con­tin­u­ous jour­ney. Our
              application sup­port al­lows you to en­trust us with the
              main­te­nance of your soft­ware once it has been re­leased. In
              turn, it means that any bug fixes and mi­nor fea­tures you’d like
              to ad­dress within the soft­ware ben­e­fit from our deep
              con­tex­tual knowl­edge of the ap­pli­ca­tion.
            </p>
          </div>
        </div>

        {/* Overlapping cards */}
        <section
          className="-mt-32 max-w-7xl mx-auto relative z-10 pb-16 px-4 sm:px-6 lg:px-8"
          aria-labelledby="contact-heading"
        >
          <h2 className="sr-only" id="contact-heading">
            Contact us
          </h2>
          <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
            {supportLinks.map((link) => (
              <div
                key={link.name}
                className="flex flex-col bg-white rounded-2xl shadow-xl"
              >
                <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                  <div className="absolute top-0 p-5 inline-block bg-gray-700 rounded-xl shadow-lg transform -translate-y-1/2">
                    <link.icon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <h3 className="text-xl font-medium text-gray-900">
                    {link.name}
                  </h3>
                  <p className="mt-4 text-base text-gray-500">
                    {link.description}
                  </p>
                </div>
                {/* <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                    <a
                      href={link.href}
                      className="text-base font-medium text-indigo-700 hover:text-indigo-600"
                    >
                      Contact us<span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div> */}
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}
